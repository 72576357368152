

jQuery(document).ready(function ($) {
	//Réinitialisation de tous les liens et styles
	$('ul.implantations--liste > li > ul').hide();
	$('ul.implantations--liste > li.active > ul').show();

	// Click depuis le pays
	$('ul.implantations--liste > li .liste--title').on('click', function(){
		var datalocation = $(this).attr('data-location');

		//Réinitialisation de tous les états
		$('ul.implantations--liste .filtre').removeClass('active');
		$('.implantations--agences .agences--liste').removeClass('active');
		$('.implantations--carte .map--svg').removeClass('left');
		$('.implantations--carte .map--svg .fond').removeClass('active');
		$('.implantations--carte .map--svg g').removeClass('active').removeClass('info');

		//Si déjà actif
		if ($(this).next("ul:visible").length != 0) {
            return false;

		//Sinon activer les états
        }else{
			$('ul.implantations--liste > li').removeClass('active');
			$('.implantations--carte .map--svg').removeClass('active');
			$('.implantations--carte .map--svg .fond').removeClass('active');

			$('.implantations--carte #map--'+datalocation).addClass('active');
            $("ul.implantations--liste > li > ul").slideUp("normal");
            $(this).next("ul").slideDown("normal");
            $(this).parent('li').addClass('active');
			return false;
        }
	}); 

	// Click depuis la liste - sous-liste
	$('ul.implantations--liste .filtre').on('click', function(){
		var datafilter = $(this).attr('data-filter');
		
		//Si déjà actif
		if( $(this).hasClass('active') == true ){
			return false;

		//Sinon activer les états
		}else{
			$('ul.implantations--liste .filtre').removeClass('active');
			$('.implantations--agences .agences--liste').removeClass('active');
			$('.implantations--carte .map--svg .fond').removeClass('active');
			$('.implantations--carte .map--svg g').removeClass('active').removeClass('info');
			
			$(this).addClass('active');
			$('.implantations--carte .map--svg').addClass('left');
			$('.implantations--carte .map--svg path[data-zone='+datafilter+']').addClass('active');
			$('.implantations--carte .map--svg g[data-point='+datafilter+']').addClass('active');
			$('.implantations--agences .'+datafilter).addClass('active');
		}
	});

	// Au survol de la liste - sous-liste
	$('ul.implantations--liste .filtre').on('mouseenter', function(){
		var datafilter = $(this).attr('data-filter');
		
		$('.implantations--carte .map--svg .fond').removeClass('hover');
		$('.implantations--carte .map--svg .point').removeClass('hover');
		
		$('.implantations--carte .map--svg path[data-zone='+datafilter+']').addClass('hover');
		$('.implantations--carte .map--svg g[data-point='+datafilter+'] .point').addClass('hover');
	}).on('mouseleave', function(){
		$('.implantations--carte .map--svg .fond').removeClass('hover');
		$('.implantations--carte .map--svg .point').removeClass('hover');
	});

	// Au survol des agences
	$('.implantations--agences .agences--liste .agence').each(function(){
		$(this).on('mouseenter', function(){
			var dataagency = $(this).attr('data-agency');
			$('.implantations--carte .map--svg g[data-pointagency='+dataagency+']').addClass('info');
		}).on('mouseleave', function(){
			$('.implantations--carte .map--svg g').removeClass('info');
		});
	});

	// Au survol des zones
	$('.implantations--carte .map--svg svg path').on('mouseenter', function(){
		var datazone = $(this).attr('data-zone');
		$('ul.implantations--liste .filtre[data-filter='+datazone+']').addClass('active');

		// Au survol des points
		$('.implantations--carte .map--svg g[data-point='+datazone+']').addClass('active');
		$('.implantations--carte .map--svg g[data-point='+datazone+']').on('mouseenter', function(){
			var datapointagency = $(this).attr('data-pointagency');
			$('.implantations--carte .map--svg g[data-point='+datazone+']').addClass('active');
			$('.implantations--carte .map--svg svg path[data-zone='+datazone+']').addClass('active');
			$('.implantations--agences .agences--liste .agence[data-pointagency='+datapointagency+']').addClass('active');
		}).on('mouseleave', function(){
			$('.implantations--carte .map--svg svg path').removeClass('active');
			$('.implantations--agences .agences--liste .agence').removeClass('active');
		});
	}).on('mouseleave', function(){
		$('.implantations--carte .map--svg g').removeClass('active');
		$('ul.implantations--liste .filtre').removeClass('active');
	});

	//Click depuis les points sur la carte
	$('.implantations--carte .map--svg svg g').on('click', function(){
		var datapoint = $(this).attr('data-point');
		var datapointagency = $(this).attr('data-pointagency');

		$('.implantations--carte .map--svg svg path').removeClass('active');
		$('.implantations--carte .map--svg svg g').removeClass('info');
		$('ul.implantations--liste .filtre').removeClass('active');
		$('.implantations--agences .agences--liste').removeClass('active');

		$('.implantations--carte .map--svg').addClass('left');
		$('.implantations--carte .map--svg path[data-zone='+datapoint+']').addClass('active');
		//$('.implantations--carte .map--svg g[data-point='+datapoint+']').addClass('active');
		$('.implantations--agences .'+datapoint).addClass('active');
		$('.implantations--agences .'+datapoint+' .agence[data-agency='+datapointagency+']').addClass('active');

		$(this).addClass('info');
	});

	$('ul.implantations--liste .show--liste').on('click', function(){
		$('ul.implantations--liste').toggleClass('open');
	});
});
