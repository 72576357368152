jQuery(document).ready(function ($) {

	$('.recherche').on('click', function() {
		setTimeout(function() { $('#recherche #s').focus() }, 300);
	});

	/* Page presentation timeline */

	$('#timeline .bloc_timeline:even').addClass('uk-animation-slide-left');
	$('#timeline .bloc_timeline:odd').addClass('uk-animation-slide-right');

	/* ANIMATION LIGNES */

	if ($(window).width() > 1200) {
		// ANimation bar scroll
		const controller = new ScrollMagic.Controller();

		// build scenes using a loop
		$('.bar_horizontal').each(function (i) {
			let thisLine = $(this);

			var action = gsap
				.timeline()
				.to(thisLine, { duration: 1.5, width: '100%', ease: '' });

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
			/* 	triggerHook: 0.4,
				offset: -200, */
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})
				.setTween(action)
				.addTo(controller);
		});

		$('.bar_vertical').each(function (i) {
			let thisLine = $(this);

			var action = gsap
				.timeline()
				.to(thisLine, { duration: 1.5, height: '100%', ease: '' });

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				/* triggerHook: 0.4,
				offset: -200, */
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})
				.setTween(action)
				.addTo(controller);
		});
	}

	if ($(window).width() > 1200) {
		/*   FIN ANIMATION LIGNES */

		const controllerOpacity = new ScrollMagic.Controller();

		$('.anim_opacity').each(function (i) {
			let thisOpacity = $(this);

			var actionLeft = gsap
				.timeline()
				.from(thisOpacity, { duration: 1.5, autoAlpha: 0, ease: '' })
				.set(thisOpacity, { autoAlpha: 1 }, '-=0.2');

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
			/* 	triggerHook: 0.4,
				offset: -200, */
					triggerHook: 0,
					offset: -800,
				reverse: false,
			})
				.setTween(actionLeft)
				.addTo(controllerOpacity);
		});



		const controllerOpacityNoReverse = new ScrollMagic.Controller();
		$('.anim_opacity_noreverse').each(function (i) {
			let thisOpacity = $(this);

			var actionLeft = gsap
				.timeline()
				.from(thisOpacity, { duration: 1.5, autoAlpha: 0, ease: '' })
				.set(thisOpacity, { autoAlpha: 1 }, '-=0.2');

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				/* triggerHook: 0.4,
				offset: -200, */
					triggerHook: 0,
					offset: -800,
				reverse: false,
			})
				.setTween(actionLeft)
				.addTo(controllerOpacityNoReverse);
		});

		/* ANIMATION BLOCS DIRECTION */

		const controllerBlocLeft = new ScrollMagic.Controller();

		$('.anim_bloc_left').each(function (i) {
			let thisBlocLeft = $(this);

			var actionLeft = gsap
				.timeline()
				.from(thisBlocLeft, {
					duration: 0.9,
					x: '-100%',
					/* autoAlpha: 0, */
					ease: '',
				})
				.set(thisBlocLeft, { x: '0', autoAlpha: 1 }, '-=0.2');

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})
				.setTween(actionLeft)
				.addTo(controllerBlocLeft);
		});

		const controllerBlocRight = new ScrollMagic.Controller();

		$('.anim_bloc_right').each(function (i) {
			let thisBlocRight = $(this);

			var actionLeft = gsap
				.timeline()
				.from(thisBlocRight, {
					duration: 1.5,
					x: '50%',
					autoAlpha: 0,
					ease: '',
				})
				.set(thisBlocRight, { x: '0', autoAlpha: 1 }, '-=0.2');

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
			/* 	triggerHook: 0.4,
				offset: -200, */
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})
				.setTween(actionLeft)
				.addTo(controllerBlocRight);
		});

		const controllerBlocTop = new ScrollMagic.Controller();

		$('.anim_bloc_top').each(function (index, element) {
			let thisBlocTop = $(this);

			var actionTop = gsap.timeline().from(thisBlocTop, 0.5, {
				y: (index + 1) * 100,
				opacity: 0,
				delay: index * 0.1,
			});

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				/* triggerHook: 0.4,
				offset: -200, */
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})

				.setTween(actionTop)
				.addTo(controllerBlocTop);
		});

		/* FIN ANIMATION BLOCS DIRECTION */

		/* ANIMATION BLOCS WIDTH */

		const controllerWidthLeft = new ScrollMagic.Controller();

		$('.anim_width_left').each(function (i) {
			let thisWidthLeft = $(this);

			var actionLeft = gsap
				.timeline()

				.from(thisWidthLeft, { duration: 1, width: '0px', ease: '' })
				.set(thisWidthLeft, { width: 'auto' }, '-=0.2');

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				/* triggerHook: 0.4,
				offset: -200, */
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})
				.setTween(actionLeft)
				.addTo(controllerWidthLeft);
		});

		const controllerWidthRight = new ScrollMagic.Controller();

		$('.anim_width_right').each(function (i) {
			let thisWidthRight = $(this);

			var actionLeft = gsap
				.timeline()

				.from(thisWidthRight, { duration: 1, width: '0%', ease: '' })
				.set(thisWidthRight, { width: 'auto' }, '-=0.2');

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				/* triggerHook: 0.4,
				offset: -200, */
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})
				.setTween(actionLeft)
				.addTo(controllerWidthRight);
		});

		const controllerHeightTop = new ScrollMagic.Controller();

		$('.anim_height_top').each(function (i) {
			let thisHeightTop = $(this);

			var actionTop = gsap
				.timeline()

				.from(thisHeightTop, { duration: 1, height: '0px', ease: '' })
				.set(thisHeightTop, { height: 'auto' }, '-=0.2');

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
			/* 	triggerHook: 0.4,
				offset: -200, */
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})
				.setTween(actionTop)
				.addTo(controllerHeightTop);
		});

		const controllerHeightTopNoReverse = new ScrollMagic.Controller();

		$('.anim_height_top_noreverse').each(function (i) {
			let thisHeightTop = $(this);

			var actionTop = gsap
				.timeline()

				.from(thisHeightTop, { duration: 0.5, height: '0px', ease: '' })
				.set(thisHeightTop, { height: 'auto' }, '-=0.2');

			new ScrollMagic.Scene({
				triggerElement: this,
				duration: 0,
				/* triggerHook: 0.4,
				offset: -200, */
				triggerHook: 0,
				offset: -800,
				reverse: false,
			})
				.setTween(actionTop)
				.addTo(controllerHeightTopNoReverse);
		});

		/* FIN ANIMATION BLOCS WIDTH */
	}

	/* Gestion de l'accordeon du block FAQ */
	if( $('.section--faq').length > 0 ){
		$(".item_faq .titre-faq").on('click', function () {
			// Si le sous-menu était déjà ouvert, on le referme :
			if ($(this).next(".rep-faq:visible").length != 0) {
				$(this).next(".rep-faq").slideUp("normal");
				$(this).removeClass('open');
			}
			// Si le sous-menu est caché, on ferme les autres et on l'affiche :
			else {
				$(".item_faq .rep-faq").slideUp("normal");
				$(".item_faq .titre-faq").removeClass('open');
				$(this).next(".rep-faq").slideDown("normal");
				$(this).addClass('open');
			}
			// On empêche le navigateur de suivre le lien :
			return false;
		});
	}

	/* Gestion de l'accordeon du block contact */
	if( $('.section--contact').length > 0 ){
		$(".section--contact .lien--accordeon").on('click', function () {
			// Si le sous-menu était déjà ouvert, on le referme :
			if ($(this).next(".lien--hide:visible").length != 0) {
				$(this).next(".lien--hide").slideUp("normal");
				$(this).removeClass('open');
			}
			// Si le sous-menu est caché, on ferme les autres et on l'affiche :
			else {
				$(".lien--hide").slideUp("normal");
				$(".lien--accordeon").removeClass('open');
				$(this).next(".lien--hide").slideDown("normal");
				$(this).addClass('open');
			}
			// On empêche le navigateur de suivre le lien :
			return false;
		});
	}
});

