import './scss/style.scss';

import '../node_modules/uikit/dist/js/uikit.min';

import '../node_modules/bootstrap/js/dist/modal';
import '../node_modules/bootstrap/js/dist/collapse';

import '../node_modules/bootstrap/dist/js/bootstrap.bundle';

//import Swiper from 'swiper';

import './js/animations';

import './js/maps';

/* import TweenMax from "gsap/TweenMax"; */

jQuery(document).ready(function ($) {
	/* if (window.devicePixelRatio !== 1){
		let scaleValue = (1.4/window.devicePixelRatio);
		$('body').css({'transform' : 'scale('+scaleValue+')', 'margin-top' : '-10%'});
	} */

	if (window.devicePixelRatio !== 1) {
		let scaleValue = 1.4 / window.devicePixelRatio;
		$('body').addClass('zoomdevice');
	}

	/* header */
	$(window).scroll(function () {
		$('header.header').toggleClass('scrolled', $(window).scrollTop() > 100);
		$('.js-scrollTo').toggleClass('scrolled', $(window).scrollTop() > 1000);

		if ($(window).scrollTop() > 100) {
			if ($('.navbar-collapse.show').length) {
				$('.navbar-toggler').trigger('click');
			}
		} else {
			if ($(window).width() > 960) {
				if (!$('.home .navbar-collapse.show').length) {
					$('.home .navbar-toggler').trigger('click');
				}
			}
		}
	});

	const $menu = $('#nav-main');

	/*$(document).mouseup((e) => {
		if (
			!$menu.is(e.target) && // if the target of the click isn't the container...
			$menu.has(e.target).length === 0
		) {
			// ... nor a descendant of the container
			if ($('.navbar-collapse.show').length) {
				$('.navbar-toggler').trigger('click');
			}
		}
	});*/

	$(function () {
		if ($(window).width() > 960) {
			$('.home .navbar-toggler').trigger('click');
		}
	});

	/* encre scroll down */
	$('a.scroll-down').on('click', function () {
		var page = $(this).attr('href');
		var speed = 750;
		$('html, body').animate(
			{ scrollTop: $(page).offset().top - 100 },
			speed
		);
		return false;
	});

	// OBFUSCATION DES MENUS
	var job = false;
	function update_dom() {
		if (job == false) {
			$('.obf').each(function () {
				var url = atob($(this).attr('rel'));
				var anchor = $(this).html();
				$(this).replaceWith(
					'<a href="' + url + '" target="_blank">' + anchor + '</a>'
				);
			});
			job = true;
		}
	}
	$(function () {
		$('body').mousemove(update_dom);
		$('body').scroll(update_dom);
	});

	$(function () {
		update_dom();
	});

	// ALERTE FOURNISSEURS
	$('#alert-fournisseurs').on('click', function () {
		$('#popup-alert-fournisseurs').toggleClass('open');
	});

	$('#alert-fournisseurs-mobile').on('click', function () {
		$('#popup-alert-fournisseurs').toggleClass('open');
	});

	$('#popup-alert-fournisseurs').on('click', function () {
		$(this).removeClass('open');
	});

	$(window).on('scroll', function () {
		$('#popup-alert-fournisseurs').removeClass('open');
	});

	// SLIDER HOME

	/*	let mainSliderSelector = '.swiper-home-intro',
		interleaveOffset = 0.5;

	let mainSliderOptions = {
		loop: true,
		speed: 1000,
		grabCursor: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		pagination: {
			el: '.swiper-pagination',
		},

		on: {
			init: function () {
				this.autoplay.stop();
			},
			imagesReady: function () {
				this.el.classList.remove('loading');
			},
			slideChangeTransitionEnd: function () {
				let swiper = this,
					captions = swiper.el.querySelectorAll('.caption');
				for (let i = 0; i < captions.length; ++i) {
					captions[i].classList.remove('show');
				}
				swiper.slides[swiper.activeIndex]
					.querySelector('.caption')
					.classList.add('show');
			},
			progress: function () {
				let swiper = this;
				for (let i = 0; i < swiper.slides.length; i++) {
					let slideProgress = swiper.slides[i].progress,
						innerOffset = swiper.width * interleaveOffset,
						innerTranslate = slideProgress * innerOffset;

					swiper.slides[i].querySelector(
						'.slide-bgimg'
					).style.transform = 'translateX(' + innerTranslate + 'px)';
				}
			},
			/*         touchStart: function() {
		let swiper = this;
		for (let i = 0; i < swiper.slides.length; i++) {
			swiper.slides[i].style.transition = "";
		}
		},
			setTransition: function (speed) {
				let swiper = this;
				for (let i = 0; i < swiper.slides.length; i++) {
					swiper.slides[i].style.transition = speed + 'ms';
					swiper.slides[i].querySelector(
						'.slide-bgimg'
					).style.transition = speed + 'ms';
				}
			},
		},
	};*/
	//let mainSlider = new Swiper(mainSliderSelector, mainSliderOptions);

	// Matching sliders
	/*   mainSlider.controller.control = navSlider;
		navSlider.controller.control = mainSlider; */

	// slider actu
	if ($('.page-template-template-groupe-prioriteclient').length != 0) {
		const swiper = new Swiper('.swiper-actus .swiper', {
			speed: 2000,
			direction: 'horizontal',
			centeredSlides: true,
			slidesPerView: 1,
			autoplay: {
				delay: 5000,
			},
			loop: true,
			navigation: {
				nextEl: '.swiper-actus-next',
				prevEl: '.swiper-actus-prev',
			},
		});
	}

	// slider realisations
	/* 	const swiperRealisations = new Swiper('.swiper-realisations', {
		speed: 2000,
		loop: true,
		spaceBetween: 5,
		navigation: {
			nextEl: '.swiper-realisations-next',
			prevEl: '.swiper-realisations-prev',
		},
		breakpoints: {
			640: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4,
			},
		},
	}); */

	// slider page blocs image texte
	/*const swiperSliderPage = new Swiper('.swiper-slider-page', {
		slidesPerView: 1,
		speed: 2000,
		loop: true,
		pagination: {
			el: '.swiper-pagination-page',
			type: 'progressbar',
		},
		navigation: {
			nextEl: '.swiper-button-next-page',
			prevEl: '.swiper-button-prev-page',
		},
	});*/

	// slider realisations galerie
	/*const swiperRealisationsGalerie = new Swiper(
		'.swiper-realisation-galerie',
		{
			speed: 2000,
			loop: true,
			spaceBetween: 15,
			navigation: {
				nextEl: '.swiper-realisation-galerie-next',
				prevEl: '.swiper-realisation-galerie-prev',
			},
			breakpoints: {
				640: {
					slidesPerView: 2,
				},
				768: {
					slidesPerView: 4,
				},
				1200: {
					slidesPerView: 4,
				},
			},
		}
	);*/

	/* realisations filter */

	const preselection = $('#secteur').data('preselection');

	if (preselection) {
		var $gridRealisations = $('.grid-realisations').isotope({
			// options
			itemSelector: '.grid-item',
			percentPosition: true,
			resizable: true,
			layoutMode: 'packery',
			packery: {
				gutter: 20,
				horizontalOrder: true,
				columnWidth: '.grid-sizer',
			},
			/* layoutMode: 'masonry',
			masonry: {
				columnWidth: '.grid-sizer',
			}, */
			filter: '.' + preselection,
		});
	} else {
		var $gridRealisations = $('.grid-realisations').isotope({
			// options
			itemSelector: '.grid-item',
			resizable: true,
			percentPosition: true,
			layoutMode: 'packery',
			packery: {
				gutter: 20,
				horizontalOrder: true,
				columnWidth: '.grid-sizer',
			},
			/* layoutMode: 'masonry',
			masonry: {
				columnWidth: '.grid-sizer',
			}, */
		});
	}

	// layout Isotope after each image loads
	$gridRealisations.imagesLoaded().progress(function () {
		$gridRealisations.isotope('layout');
	});

	/* 	$('.filtres-realisations  option').on('click', function () {
		const filters = [];
		const secteur = $('#secteur option:selected').attr('data-filter');
		const pays = $('#pays option:selected').attr('data-filter');
		const typologie = $('#typologie option:selected').attr('data-filter');

		if (secteur) {
			filters.push(secteur);
		}
		if (pays) {
			filters.push(pays);
		}
		if (typologie) {
			filters.push(typologie);
		}

		var filterValue = filters.join();
		console.log(filters);
		const optionsRea = {};


		optionsRea.filter = filterValue;


		$gridRealisations.isotope(optionsRea);
	}); */

	$('.filtres-realisations  li').on('click', function () {
		$gridRealisations.isotope({
			filter: '.' + $(this).attr('class'),
		});
		//test
		$gridRealisations.isotope('layout');
		//
		$('.filtres-realisations .active').removeClass('active');
		$(this).addClass('active');

		window.location.href =
			window.location.href.split('#')[0] +
			'#' +
			$(this).attr('class').replace('active', '');

		localStorage.setItem('selectionActive', $(this).attr('data-filter'));
	});

	let urlParams = new URLSearchParams(window.location.search);
	const back = urlParams.get('back');
	if (back != 1) {
		localStorage.setItem('selectionActive', '.all');
	}

	var hashID = window.location.hash.substring(1);

	if (hashID) {
		$gridRealisations.isotope({ filter: '.' + hashID });
		$('.filtres-realisations .active').removeClass('active');
		$('.filtres-realisations li[data-filter=".' + hashID + '"]').addClass(
			'active'
		);
		console.log(hashID);
	}
	/*$container is an isotope container.it may be different
    a container is a wrapper for filter data
    here is masonry-grid is a container*/

	/*other filter selected logic you can apply to your HTML structure here*/

	/* if(localStorage.getItem('selectionActive')){
		$('.filtres-realisations li[data-filter="' + localStorage.getItem('selectionActive') + '"]').trigger('click');
	} */

	/* actus filter */

	var $grid = $('.grid').isotope({
		// options
		itemSelector: '.grid-item',
		//layoutMode: 'fitRows',
		resizable: false,
		percentPosition: true,
		layoutMode: 'masonry',
		masonry: {
			columnWidth: '.grid-item',
		},
		getSortData: {
			name: '.name',
			date: '.date parseInt',
		},
	});

	$('.filtres  option').on('click', function () {
		/* 	var filterValue = $('#tri_cat option:selected').attr('data-filter'); */
		var sortValue = $('#choix_tri option:selected').val();
		/* var order = $('#order option:selected').val(); */
		const options = {};

		// if (filterValue) {
		//options.filter = filterValue;
		// }

		if (sortValue) {
			options.sortBy = sortValue;
		}

		/* 		if (order == 'ASC') {
			options.sortAscending = true;
		} else {
			options.sortAscending = false;
		} */

		console.log(options);
		$grid.isotope(options);
	});

	$('#actualites .filtres  li').on('click', function () {
		$grid.isotope({
			filter: '.' + $(this).attr('class'),
		});
		$('.filtres .active').removeClass('active');
		$(this).addClass('active');

		localStorage.setItem('selectionActive', $(this).attr('data-filter'));
	});

	// NEWSLETTERS
	var $newsletter = $('.newsletters').isotope({
		itemSelector: '.newsletter',
		resizable: true,
		percentPosition: true,
		layoutMode: 'masonry',
		masonry: {
			columnWidth: '.newsletter',
			gutter: 16,
		},
		getSortData: {
			name: '.name',
			date: '.date parseInt',
		},
	});

	$('.bloc--newsletters #tri_cat li').on('click', function () {
		$newsletter.isotope({
			filter: '.' + $(this).attr('class'),
		});
		$('.bloc--newsletters #tri_cat .active').removeClass('active');
		$(this).addClass('active');

		localStorage.setItem('selectionActive', $(this).attr('data-filter'));
	});

	/* Page contact 1er onglet actif */
	if (
		$('.form-contact .nav-tabs > li:first-child > a') &&
		$('.form-contact .nav-tabs > li:first-child > a')[0]
	) {
		$('.form-contact .nav-tabs > li:first-child > a')[0].click();
	}

	//preselection formulaire projet
	const selectionProjet = $('.formulaire-projet').data('select-projet');

	if (selectionProjet) {
		const entreprise = $('label#label_1_7_0');
		const investisseur = $('label#label_1_7_1');
		const promoteur = $('label#label_1_7_2');
		const collectivite = $('label#label_1_7_3');

		let selection = '';
		switch (selectionProjet) {
			case 'entreprise':
				selection = entreprise;
				break;
			case 'a-company':
				selection = entreprise;
				break;
			case 'investisseur':
				selection = investisseur;
				break;
			case 'an-investor':
				selection = investisseur;
				break;
			case 'promoteur':
				selection = promoteur;
				break;
			case 'a-promoter':
				selection = promoteur;
				break;
			case 'collectivite':
				selection = collectivite;
				break;
			case 'a-local-authority':
				selection = collectivite;
				break;
		}
		$(selection).trigger('click');
	}

	//preselection formulaire contact
	const selectionContact = $('.form-contact').data('select-contact');

	if (selectionContact) {
		$('.form-contact > ul > li > a').removeClass('active');
		$('.form-contact .tab-content .tab-pane').removeClass('active');
		$(`.${selectionContact}`).addClass('active');
		$(`#${selectionContact}`).addClass('active');
	}

	//BLOQUER Selection Batiment du Formulaire Projet
	/* 	$('#field_1_29 input ,#field_1_28 input').on('click', function () {
		const selection = $(
			'#field_1_29 input:checked ,#field_1_28 input:checked'
		).length;
		if (selection == 0) {
			$('#field_1_29 input ,#field_1_28 input').removeAttr('disabled');
		} else {
			$('#field_1_29 input ,#field_1_28 input').prop('disabled', 'true');
			$(this).removeAttr('disabled');
		}
	}); */

	$('#field_1_29 input ,#field_1_28 input').on('click', function () {
		$('#field_1_29 input ,#field_1_28 input').prop('checked', false);
		$(this).prop('checked', true);
	});

	$('.bloc--commercial .bt--contact').each(function () {
		$(this).on('click', function () {
			$(this).addClass('active');
		});
	});

	animChaineValeurs();
	mapRegion();
});

function animChaineValeurs() {
	$('.svg--desktop .theme').each(function (i) {
		$(this)
			.find('.point')
			.each(function (j) {
				$(this)
					.on('mouseenter', function () {
						$('.theme--texte_' + i + '-' + j).addClass('active');
					})
					.on('mouseleave', function () {
						$('.theme--texte_' + i + '-' + j).removeClass('active');
					});
			});
	});

	$('.svg--mobile .theme').each(function (i) {
		$(this)
			.find('.point')
			.each(function (j) {
				$(this)
					.on('mouseenter', function () {
						$('.theme--texte_' + i + '-' + j).addClass('active');
					})
					.on('mouseleave', function () {
						$('.theme--texte_' + i + '-' + j).removeClass('active');
					});
			});
	});
}

function mapRegion() {
	$('.bloc--implantations-france .implantation--liste ul li').each(
		function () {
			var dataRegion = $(this).attr('data');
			var linkRegion = $(this).find('a').attr('href');

			console.log(linkRegion);

			$(this)
				.on('mouseenter', function () {
					$(this).addClass('active');
					$(
						'.bloc--implantations-france .visuel svg path#' +
							dataRegion
					).addClass('active');
				})
				.on('mouseleave', function () {
					$(this).removeClass('active');
					$(
						'.bloc--implantations-france .visuel svg path#' +
							dataRegion
					).removeClass('active');
				});

			$('.bloc--implantations-france .visuel svg path#' + dataRegion).on(
				'click',
				function () {
					window.open(linkRegion, '_blank');
				}
			);
		}
	);

	$('.bloc--implantations-france .visuel svg path').each(function () {
		var dataMap = $(this).attr('id');
		$(this)
			.on('mouseenter', function () {
				$(this).addClass('active');
				$(
					'.bloc--implantations-france .implantation--liste ul li[data=' +
						dataMap +
						']'
				).addClass('active');
			})
			.on('mouseleave', function () {
				$(this).removeClass('active');
				$(
					'.bloc--implantations-france .implantation--liste ul li[data=' +
						dataMap +
						']'
				).removeClass('active');
			});
	});
}
